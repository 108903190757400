<template>
  <div class="p-grid" style="margin-right:-37px;">
    <Breadcrumb class="p-col-11" :home="breadcrumb.home" :model="breadcrumb.items" style="padding-top:0px;background: none;border: none;" />
    <Button @click="back()" label="返回" class="p-col-1 p-button-sm p-button-outlined p-button-secondary" style="padding:0px;width:65px;border:1px solid rgba(217, 217, 217, 1);margin-bottom:10px;" />
  </div>
  <div class="card">
    <div class="p-grid">
      <div class="p-col-4">
        <label>指标类型：</label>
        <Dropdown @keypress="$event.keyCode==13?redraw():{}" style="width:60%" :filter="true" placeholder="请选择" :options="dataTypeList" v-model="condition.dataType" optionLabel="cname" option-value="ename" />
      </div>
      <div class="p-col-4">
        <label>时间段：</label>
        <Calendar @keypress="$event.keyCode==13?redraw():{}" style="width: 70%" placeholder="请选择" v-model="condition.timeRange" :manualInput="false" selectionMode="range" dateFormat="yy/mm/dd" :numberOfMonths="2"
          :show-icon="true" />
      </div>
      <div class="p-col-4">
        <Button style="height:33px;border-color: #d9d9d9" @click="reset()" label="重置" class="p-button-outlined p-button-secondary p-mr-2" />
        <Button style="background: #12A25D;height:33px;" label="查询" class="p-mr-2" @click="redraw()" />
      </div>
    </div>
    <div v-if="chartData[0]" style="padding:10px 0px 10px 0px;">
      采集通道：
      <labe @click="channelChanged(item)" v-bind:key="item" v-for="item of chartData[0].channel" :class="{'channel-selected':condition.channel==item,'channel-unselected':condition.channel!=item}"
        style="margin:10px;cursor:pointer;">
        {{item}}
      </labe>
    </div>
  </div>

  <div class="card">
    <div style="margin-left:6px;font-size:18px;font-weight:bold;">
      <div style="float:left;width:auto;margin-top:3px;">图表结果</div>
      <Button @click="exportCSV()" label="下载" style="float:right;width:61px;height:32px;border: 1px solid #D9D9D9;color:rgba(0, 0, 0, 0.65);font-size:14px;" class="p-button-outlined p-button-secondary" />
    </div>
    <div :id="echartsELeId" style="width:100%;height:600px;margin-top:40px;"></div>
  </div>
  <Toast />

</template>

<script>
export default {
  components: {},
  data() {
    return {
      echarts: window["echarts"],
      echartsELeId: "main_" + new Date().getTime(),
      breadcrumb: {
        home: { label: "种植项目", to: "#" },
        items: [
          { label: "数据分析", to: "/data/compare" },
          { label: "同一图表对比", to: "#" },
        ],
      },
      dataTypeList: [],
      chartData: [], // 不能是null
      condition: {
        dataType: null,
        timeRange: [], // 不能为空，默认要使用
        channel: "FIRST", // 默认显示第一个管道
      },
      myChart: null,
      currChartOption: null,
    };
  },
  mounted() {
    // 显示页面预加载动画
    let myChart = this.echarts.init(document.getElementById(this.echartsELeId));
    myChart.showLoading({
      text: "正在加载图表...",
      color: "rgba(18, 162, 93, 1)",
      textColor: "rgba(18, 162, 93, 1)",
      maskColor: "rgba(18, 162, 93, 0.3)",
    });

    // 根据url参数设置时间段
    if (this.$route.query.startDate && this.$route.query.endDate) {
      this.condition.timeRange = [
        new Date(this.$route.query.startDate),
        new Date(this.$route.query.endDate),
      ];
    } else {
      // 如果父页面没有选择时间，则默认为前一周
      this.condition.timeRange = [
        new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
        new Date(),
      ];
    }
    this.getTypeList();
  },
  methods: {
    exportCSV() {
      // 查找指标类型的cname
      let cname = "";
      for (let i in this.dataTypeList) {
        let option = this.dataTypeList[i];
        if (option.ename == this.condition.dataType) {
          cname = option.cname;
        }
      }

      let ownOption = this.currChartOption;

      let content = "时间,参数,";
      for (let i in ownOption.legend.data) {
        let curr = ownOption.legend.data[i];
        content += curr + ",";
      }
      content += "\n";

      for (let i in ownOption.xAxis.data) {
        content += ownOption.xAxis.data[i] + ","; // 时间
        content += cname + ","; // 指标名称
        for (let j in ownOption.series) {
          let ser = ownOption.series[j];
          if (ser.data.length > 0) {
            let value = ser.data[i];
            content += (value ? value : "") + ",";
          }
        }
        content += "\n";
      }

      // 创建Blob对象 传入一个合适的MIME类型
      const blob = new Blob(["\ufeff" + content], {
        type: "text/csv,charset=UTF-8",
      });
      // 使用 Blob 创建一个指向类型化数组的URL
      const csvUrl = URL.createObjectURL(blob);
      let link = document.createElement("a");
      link.download = cname + "_" + new Date().getTime() + ".csv"; //文件名字
      link.href = csvUrl;
      link.click();
    },
    channelChanged(channel) {
      this.condition.channel = channel;
      // 拼接设备id
      let devids = "";
      let devsObj = JSON.parse(this.$route.query.devs);
      for (let i in devsObj) {
        devids += devsObj[i] + ",";
      }
      devids = devids.substring(0, devids.length - 1);
      this.query(
        devids,
        this.condition.dataType,
        channel,
        new Date(this.condition.timeRange[0]).toLocaleDateString(),
        new Date(this.condition.timeRange[1]).toLocaleDateString()
      );
    },
    back() {
      this.$router.push("/data/compare");
    },
    redraw() {
      this.condition.channel = "FIRST";

      if (!this.condition.dataType || !this.condition.timeRange) {
        this.$toast.add({
          severity: "warn",
          summary: "输入错误！",
          detail: "请将条件输入完整！",
          life: 3000,
        });
        return;
      }

      // 拼接设备id
      let devids = "";
      let devsObj = JSON.parse(this.$route.query.devs);
      for (let i in devsObj) {
        devids += devsObj[i] + ",";
      }
      devids = devids.substring(0, devids.length - 1);
      this.query(
        devids,
        this.condition.dataType,
        "FIRST",
        new Date(this.condition.timeRange[0]).toLocaleDateString(),
        new Date(this.condition.timeRange[1]).toLocaleDateString()
      );
    },
    query(devIds, kpiEName, channel, start, end) {
      // new Date(this.condition.timeRange[1]).toLocaleDateString()
      let endTime = new Date(
        new Date(end).getTime() + 1 * 24 * 60 * 60 * 1000
      ).toLocaleDateString();
      // 注意：未知原因导致无法全局公用echarts对象，否则导致tooltip出不来
      let myChart = this.echarts.init(
        document.getElementById(this.echartsELeId)
      );
      myChart.showLoading({
        text: "正在加载图表...",
        color: "rgba(18, 162, 93, 1)",
        textColor: "rgba(18, 162, 93, 1)",
        maskColor: "rgba(18, 162, 93, 0.3)",
      });
      this.$http(
        "/kpi/getOneCompareEChartsData",
        "get",
        {
          devIds: devIds,
          kpiEName: kpiEName,
          channel: channel,
          start: start,
          end: endTime,
        },
        (resp) => {
          this.chartData = resp.data;
          // 等待dom渲染完成
          setTimeout(() => {
            if (this.condition.channel == "FIRST") {
              this.condition.channel = this.chartData[0].channel[0];
            }
            this.drawChart(resp.data);
          }, 200);
        }
      );
    },
    drawChart(item) {
      // 默认规则
      // 处理timelist，如果默认时分秒都是00:00:00，则省略所有timelist的时分秒
      let isAllTimeZero = true;
      for (let i in item[0].time) {
        let time = item[0].time[i];
        if (!time.endsWith("00:00:00")) {
          isAllTimeZero = false;
        }
      }
      if (isAllTimeZero) {
        for (let i in item[0].time) {
          item[0].time[i] = item[0].time[i].replace("00:00:00", "").trim();
        }
      }

      // 找到记录的最后七天的起始时间点
      // let endTime = new Date().getTime();
      // // 判断记录是否为空
      // if(item.length > 0 && item[0].time.length > 0){
      //   endTime = new Date(item[0].time[item[0].time.length - 1].replace(/ /g,"T")).getTime();
      // }
      // let startTime = endTime - 7*24*60*60*1000;
      // for(let i in item[0].time){
      //   let time = item[0].time[i];
      //   let dateObj = new Date(time);
      //   if(typeof startTime == 'number' && dateObj.getTime() >= startTime){
      //     startTime = time;
      //   }
      //   if(typeof endTime == 'number' && dateObj.getTime() >= endTime){
      //     endTime = time;
      //   }
      // }

      // 求所有设备的时间交集，并对其进行正序排序，因为部分设备的时间可能不一致
      let all_time = [];
      for (let i in item) {
        let time_list = item[i].time;
        for (let j in time_list) {
          let ts = new Date(time_list[j].replace(/ /g, "T")).getTime();
          if (all_time.indexOf(ts) == -1) {
            all_time.push(ts);
          }
        }
      }
      all_time.sort(); // 时间正序排序
      let endTime = all_time[all_time.length - 1];
      let startTime = endTime - 7 * 24 * 60 * 60 * 1000;
      // 把时间转为date str形式，为避免影响之前的变量，因此要克隆
      let new_time = JSON.parse(JSON.stringify(all_time));
      for (let i in new_time) {
        let d = new Date(new_time[i]);
        new_time[i] =
          d.getFullYear() +
          "-" +
          (d.getMonth() < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1) +
          "-" +
          (d.getDate() < 10 ? "0" + d.getDate() : d.getDate()) +
          " " +
          (d.getHours() < 10 ? "0" + d.getHours() : d.getHours()) +
          ":" +
          (d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes()) +
          ":" +
          (d.getSeconds() < 10 ? "0" + d.getSeconds() : d.getSeconds());
      }
      let myChart = window["echarts"].init(
        document.getElementById(this.echartsELeId)
      );
      myChart.showLoading({
        text: "正在加载图表...",
        color: "rgba(18, 162, 93, 1)",
        textColor: "rgba(18, 162, 93, 1)",
        maskColor: "rgba(18, 162, 93, 0.3)",
      });
      let option = {
        title: {
          // text: 'okok',
          // subtext: '纯属虚构'
        },
        tooltip: {
          trigger: "axis",
          formatter(params) {
            let max = -99999;
            let min = 99999;
            let avg = 0;
            let sum = 0;
            let html = params[0].axisValue + "<br/><br/>";
            for (let i in params) {
              let record = params[i];
              html +=
                record.seriesName +
                "：" +
                record.value +
                " " +
                item[0].valueUnit +
                "<br/>";
              sum += record.value;
              if (record.value > max) {
                max = record.value;
              }
              if (record.value < min) {
                min = record.value;
              }
            }
            avg = (sum / params.length).toFixed(2);
            max = max.toFixed(2);
            min = min.toFixed(2);

            html +=
              "<hr/>最大值：" +
              max +
              " " +
              item[0].valueUnit +
              "<br/>最小值：" +
              min +
              " " +
              item[0].valueUnit +
              "<br/>平均值：" +
              avg +
              " " +
              item[0].valueUnit;
            return html;
          },
        },
        legend: {
          data: [],
        },
        // toolbox: {
        //   show: true,
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        dataZoom: [
          {
            type: "slider",
            startValue: startTime,
            endValue: endTime,
          },
        ],
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: new_time,
        },
        yAxis: {
          type: "value",
          // axisLabel: {
          //   formatter: '{value} °C'
          // }
        },
        series: [],
      };

      for (let i in item) {
        let one = item[i];

        // 把当前设备的时间-值转为json的key-value形式，便于下面的快速判断是否存在某个时间的值
        let meta = {};
        for (let j in one.time) {
          meta[new Date(one.time[j].replace(/ /g, "T")).getTime()] =
            one.value[j];
        }

        // 循环主时间轴，如果此设备存在对应时间的值，则使用，如果不存在，则插入null
        let all_data = [];
        for (let j in all_time) {
          let curr_time = all_time[j];
          if (meta[curr_time]) {
            all_data.push(meta[curr_time]);
          } else {
            all_data.push(null);
          }
        }

        option.legend.data.push(
          one.detail.dname + "（" + one.detail.pid + "-" + one.detail.did + "）"
        );
        option.series.push({
          name:
            one.detail.dname +
            "（" +
            one.detail.pid +
            "-" +
            one.detail.did +
            "）",
          type: "line",
          data: all_data,
          connectNulls: true,
        });
      }

      this.currChartOption = option;

      // 使用刚指定的配置项和数据显示图表
      myChart.setOption(option);
      myChart.hideLoading();
    },
    reset() {
      this.condition.dataType = null;
      this.condition.timeRange = null;
    },
    getTypeList() {
      this.$http(
        "/kpiname/listByDevType",
        "post",
        { devs: this.$route.query.devs },
        (resp) => {
          // 默认查询第一个指标类型的数据
          if (resp.data.length > 0) {
            this.dataTypeList = resp.data;
            this.condition.dataType = resp.data[0].ename;

            // 拼接设备id
            let devids = "";
            let devsObj = JSON.parse(this.$route.query.devs);
            for (let i in devsObj) {
              devids += devsObj[i] + ",";
            }
            devids = devids.substring(0, devids.length - 1);
            this.query(
              devids,
              resp.data[0].ename,
              "FIRST",
              new Date(this.condition.timeRange[0]).toLocaleDateString(),
              new Date(this.condition.timeRange[1]).toLocaleDateString()
            );
          }
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.channel-selected {
  font-weight: bold;
  color: white;
  background-color: #12a25d;
  border-radius: 50px;
  padding: 2px 4px 2px 4px;
}
.channel-unselected {
  font-weight: normal;
  color: black;
  background-color: white;
  border-radius: 50px;
  padding: 2px 4px 2px 4px;
}
.a-button {
  color: #12a25d;
  font-size: 14px;
  margin-left: 8px;
  margin-right: 8px;
}

.admin-status {
  width: 56px;
  height: 22px;
  border: 1px double #91d5ff;
  color: #1890ff;
  font-size: 12px;
  padding: 4px 8px 4px 8px;
  background-color: #e6f7ff;
}

.user-status {
  width: 56px;
  height: 22px;
  border: 1px double #b7eb8f;
  color: #52c41a;
  font-size: 12px;
  padding: 4px 8px 4px 8px;
  background-color: #f6ffed;
}

.select1 {
  display: flex;
  justify-content: space-between;
}

//去掉表格底部的线条
::v-deep(.p-paginator-bottom) {
  border-bottom: none !important;
}
::v-deep(.p-checkbox .p-checkbox-box.p-highlight) {
  border-color: #12a25d;
  background: #12a25d;
}
::v-deep(.p-checkbox .p-checkbox-box.p-highlight:hover) {
  border-color: #12a25d;
  background: #12a25d;
}
::v-deep(.p-calendar-w-btn .p-button) {
  border-color: #12a25d;
  background: #12a25d;
}
::v-deep(.p-checkbox .p-checkbox-box:hover) {
  border-color: #12a25d;
}
</style>
